/* eslint-disable no-unused-vars */
export enum PathName {
  refillUrl = 'refillUrl',
  myBetsUrl = 'myBetsUrl',
  myLimitsUrl = 'myLimitsUrl',
  oldRefillUrl = 'oldRefillUrl',
  myAccountUrl = 'myAccountUrl',
  withdrawalUrl = 'withdrawalUrl',
  registrationUrl = 'registrationUrl',
  oldWithdrawalUrl = 'oldWithdrawalUrl',
}
/* eslint-enable no-unused-vars */

export type PathNameType = keyof typeof PathName;

const snai: Record<PathNameType, string> = {
  refillUrl: '/dashboard/ricarica',
  myBetsUrl: '/dashboard/le-mie-scommesse',
  myLimitsUrl: '/dashboard/limiti-di-gioco',
  myAccountUrl: '/dashboard/il-mio-conto',
  oldRefillUrl: '/dashboard/ricarica',
  withdrawalUrl: '/dashboard/preleva',
  registrationUrl: '/registrati',
  oldWithdrawalUrl: '/dashboard/preleva',
};

const happybet: Record<PathNameType, string> = {
  refillUrl: '/dashboard/refill',
  myBetsUrl: '/dashboard/my-bets',
  myLimitsUrl: '/dashboard/game-limits',
  myAccountUrl: '/dashboard/my-account',
  oldRefillUrl: '/dashboard/refill',
  withdrawalUrl: '/dashboard/withdrawal',
  registrationUrl: '/register',
  oldWithdrawalUrl: '/dashboard/withdrawal',
};

const routes: Record<string, Record<PathNameType, string>> = { snai, happybet };

export const getRouteByKey = (key: PathName | PathNameType): string => {
  const site = `${process.env.NEXT_PUBLIC_MODULE_SITE ?? 'snai'}`.toLowerCase();
  return routes[site][key];
};
