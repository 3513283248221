import { ChatBotDataDto, SignupState } from 'features/signup/types';
import { Avatar, AvatarsResponseApi, MyAvatar } from 'lib/api/avatarsResponseApi';
import { BonusApiResponse, BonusCash, BonusGold } from 'lib/api/bonusApiResponse';
import { DetailMovimentiResponseApi, DetailedBetResponseApi } from 'lib/api/detailedBetResponseApi';
import {
  DetailMovimentiResponseApi as DetailMovimentiResponseApiHappybet,
  DetailedBetResponseApi as DetailedBetResponseApiHappybet,
} from 'sites/happybet/lib/api/detailedBetResponseApi';
import { BonusFreespinFunBonus, MappedBonusType } from 'types/dashboard/mappedBonusType';
import {
  UsersApiGadBaseEsitoResDto,
  UsersGetProfileResponse,
  UsersGetStatsResDto,
  UsersSignUpDataDto,
} from 'types/swagger';

import { ResetPasswordState } from 'features/resetPassword/resetPasswordSlice';
import { CredentialRequest } from 'features/resetPassword/types/credentialRequest';
import { CheckFiscalCodeResponse } from 'features/signup/types/checkFiscalCodeResponse';
import { SignupResponse } from 'features/signup/types/signUpResponse';
import { SignupRequest } from 'features/signup/types/signupRequest';
import { CashoutResponseApi } from 'lib/api/cashoutResponseApi';
import { CashoutTotaleResponseApi } from 'lib/api/cashoutTotaleResponseApi';
import { MovimentiResponseApi } from 'lib/api/movimentiResponseApi';
import { PollingCashoutResponseApi } from 'lib/api/pollingCashoutResponseApi';
import { ScommesseResponseApi } from 'lib/api/scommesseResponseApi';
import { BonusCarrelloDataDto } from 'lib/api/sport/cartBonusResponseApi';
import { isSnaiSite } from 'utility/constant';
import { TIPOLOGY_SEND_INFO } from 'utility/dashboard/constants';
import { isTruthy } from 'utility/functions';
import { apiSlice } from './apiSlice';

/* eslint-disable no-unused-vars */
enum ContactType {
  EMAIL = 1,
  TEL = 2,
}
/* eslint-enable no-unused-vars */

const UserEndPointPrefix = 'users/user';
const MovementEndPointPrefix = 'movimenti/movimenti';
const MovementEndPointPrefixHappybet = 'movimenti/hbet/movimenti';

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postFeedUnregistration: builder.mutation<void, string>({
      query: (comment) => ({
        url: `${UserEndPointPrefix}/feedback/quitregistration`,
        body: JSON.stringify({
          comment: comment,
        }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    postSignUpData: builder.mutation<string, SignupState | UsersSignUpDataDto>({
      query: (data: SignupState | UsersSignUpDataDto) => ({
        url: `${UserEndPointPrefix}/sessionsignupdata`,
        body: JSON.stringify(data),
        method: 'POST',
        header: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    getSessionData: builder.query<UsersSignUpDataDto | ChatBotDataDto | BonusCarrelloDataDto, string>({
      query: (key) => `${UserEndPointPrefix}/session?key=${key}`,
    }),
    checkUsername: builder.query<{ success: boolean }, { username: string }>({
      query: ({ username }) => `${UserEndPointPrefix}/profile/username?username=${username}`,
    }),
    calculateFiscalCode: builder.query<
      { fiscalCode: string },
      {
        name: string;
        surname: string;
        birthday: string;
        gender: string;
        catastoCode: string;
      }
    >({
      query: (data) => ({
        url: `${UserEndPointPrefix}/profile/fiscalcode`,
        headers: {
          'Content-type': 'application/text',
        },
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    sendOTP: builder.query<{ userId: string }, { type: 'email' | 'tel'; contact: string }>({
      query: (data) => {
        const body = {
          carta: '',
          tipo_contatto: ContactType[data.type.toUpperCase() as keyof typeof ContactType],
          contatto: data.contact,
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contract/contact/otp`,
          body: JSON.stringify(body),
        };
      },
      transformResponse: (response: { id_utente: string }) => ({
        userId: response.id_utente,
      }),
    }),
    verifyOTP: builder.query<{ message: string }, { type: 'email' | 'tel'; contact: string; otp: string; id: string }>({
      query: (data) => {
        const body = {
          carta: '',
          tipo_contatto: ContactType[data.type.toUpperCase() as keyof typeof ContactType],
          contatto: data.contact,
          otp_verifica: data.otp,
          id_utente: data.id,
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contract/contact/otp/validate`,
          body: JSON.stringify(body),
        };
      },
    }),
    sendOTPForEdit: builder.query<{ userId: string }, { type: 'email' | 'tel'; contact: string }>({
      query: (data) => {
        const body = {
          num_carta: '',
          tipo_contatto: ContactType[data.type.toUpperCase() as keyof typeof ContactType],
          contatto: data.contact,
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contact/otp`,
          body: JSON.stringify(body),
        };
      },
      transformResponse: (response: { id_utente: string }) => ({
        userId: response.id_utente,
      }),
    }),
    verifyOTPForEdit: builder.query<{ message: string }, { type: 'email' | 'tel'; contact: string; otp: string }>({
      query: (data) => {
        const body = {
          num_carta: '',
          tipo_contatto: ContactType[data.type.toUpperCase() as keyof typeof ContactType],
          contatto: data.contact,
          otp_verifica: data.otp,
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contact/otp/validate`,
          body: JSON.stringify(body),
        };
      },
    }),
    signUp: builder.query<SignupResponse, SignupState>({
      query: (data) => {
        const body: SignupRequest = {
          cf: data.fiscalCode,
          nome: data.name,
          btag: data.btag ?? '',
          sesso: Number(data.gender),
          email: data.email,
          cognome: data.surname,
          password: data.password,
          username: data.username,
          numeroDoc: data.documentNumber,
          spidAccess: data.isSpid,
          idSessione: crypto.randomUUID(),
          idOtpEmail: data.userSessionEmail! ?? '',
          codiceIobb: data.antiFraudCode ?? '',
          codicePromo: data.promoCode,
          dataNascita: new Date(data.birthDate),
          provenienza: data.provenienceCode ?? '',
          capResidenza: data.cap,
          capDomicilio: data.domicileCap || data.cap,
          comuneNascita: data.cityOfBirth,
          flagMarketing: data.marketingUsage ? 1 : 0,
          idOtpTelefono: data.userSessionTelephone! ?? '',
          codiceTipoDoc: Number(data.documentType),
          telefonoMobile: data.telephone,
          comuneResidenza: data.city,
          dataRilascioDoc: new Date(data.releaseDate),
          dataScadenzaDoc: new Date(data.expirationDate),
          comuneDomicilio: data.domicileCity || data.city,
          flagProfilazione: data.profilingUsage ? 1 : 0,
          limiteVersamento: 100 * Number(data.weeklyMaximal),
          luogoRilascioDoc: data.releasePlace,
          provinciaNascita: data.provinceOfBirth,
          codiceTipoAutorita: data.releasedBy,
          indirizzoDomicilio: data.domicileAddress || data.address,
          indirizzoResidenza: data.address,
          provinciaDomicilio: data.domicileProvince || data.province,
          provinciaResidenza: data.province,
          rispostaDomandaSegreta: data.secretAnswer,
          codiceTipoDomandaSegreta: Number(data.secretQuestion),
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contract/new`,
          body: JSON.stringify(body),
        };
      },
    }),
    signUpSpid: builder.mutation<SignupResponse, SignupState>({
      query: (data) => {
        const body: SignupRequest = {
          cf: data.fiscalCode,
          nome: data.name,
          btag: data.btag ?? '',
          sesso: Number(data.gender),
          email: data.email,
          cognome: data.surname,
          password: data.password,
          username: data.username,
          numeroDoc: data.documentNumber,
          spidAccess: data.isSpid,
          idSessione: crypto.randomUUID(),
          idOtpEmail: data.userSessionEmail! ?? '',
          codiceIobb: data.antiFraudCode ?? '',
          codicePromo: data.promoCode,
          dataNascita: new Date(data.birthDate),
          provenienza: data.provenienceCode ?? '',
          capResidenza: data.cap,
          capDomicilio: data.domicileCap || data.cap,
          comuneNascita: data.cityOfBirth,
          flagMarketing: data.marketingUsage ? 1 : 0,
          idOtpTelefono: data.userSessionTelephone! ?? '',
          codiceTipoDoc: Number(data.documentType),
          telefonoMobile: data.telephone,
          comuneResidenza: data.city,
          dataRilascioDoc: new Date(data.releaseDate),
          dataScadenzaDoc: new Date(data.expirationDate),
          comuneDomicilio: data.domicileCity || data.city,
          flagProfilazione: data.profilingUsage ? 1 : 0,
          limiteVersamento: 100 * Number(data.weeklyMaximal),
          luogoRilascioDoc: data.releasePlace,
          provinciaNascita: data.provinceOfBirth,
          codiceTipoAutorita: data.releasedBy,
          indirizzoDomicilio: data.domicileAddress || data.address,
          indirizzoResidenza: data.address,
          provinciaDomicilio: data.domicileProvince || data.province,
          provinciaResidenza: data.province,
          rispostaDomandaSegreta: data.secretAnswer,
          codiceTipoDomandaSegreta: Number(data.secretQuestion),
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contract/new`,
          body: JSON.stringify(body),
        };
      },
    }),
    postFeedback: builder.query<void, { rating: number; comment?: string }>({
      query: (res) => ({
        method: 'POST',
        url: `${UserEndPointPrefix}/feedback/registration`,
        body: JSON.stringify({
          comment: res.comment || '',
          rating: String(res.rating),
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    checkFiscalCode: builder.query<CheckFiscalCodeResponse, { fiscalCode: string }>({
      query: ({ fiscalCode }) => `${UserEndPointPrefix}/profile/credential?codicefiscale=${fiscalCode}`,
    }),
    verifyUser: builder.query<
      { cell: string; email: string },
      {
        fiscalCode: string;
        name: string;
        surname: string;
        email: string;
        questionCode?: number;
        answer?: string;
      }
    >({
      query: (request) => {
        return {
          body: JSON.stringify({
            codice_fiscale: request.fiscalCode,
            datadinascita: '', // lasciare vuoto
            carta: '', // lasciare vuoto
            num_carta: '', // lasciare vuoto
            nome: request.name,
            cognome: request.surname,
            cell: '', // lasciare vuoto
            email: request.email,
            cod_domanda: request.questionCode,
            risposta: request.answer,
            tipoinvio: '', // lasciare vuoto
          }),
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/credential`,
        };
      },
    }),
    resetPassword: builder.query<void, ResetPasswordState>({
      query: (data) => {
        const body: CredentialRequest = {
          cod_domanda: data.secretQuestion,
          codice_fiscale: data.fiscalCode,
          cognome: data.surname,
          nome: data.name,
          num_carta: '',
          tipoinvio: data.channelType,
          risposta: data.answer,
          carta: '',
          datadinascita: '',
          cell: data.telephoneNumber,
          email: data.email,
        };
        return {
          url: `${UserEndPointPrefix}/profile/credential`,
          method: 'POST',
          body: JSON.stringify(body),
        };
      },
    }),
    // TODO da rivedere
    activeAccount: builder.query<SignupResponse, SignupState>({
      query: (data) => {
        const body: SignupRequest = {
          capDomicilio: '', //TODO MANCA DA API
          capResidenza: '', //TODO MANCA DA API
          cf: data.fiscalCode,
          codiceIobb: data.antiFraudCode,
          codicePromo: data.promoCode,
          codiceTipoDoc: Number(data.documentType),
          codiceTipoAutorita: data.releasedBy,
          codiceTipoDomandaSegreta: Number(data.secretQuestion),
          cognome: data.surname,
          comuneDomicilio: data.city,
          comuneNascita: data.cityOfBirth,
          comuneResidenza: data.city,
          dataNascita: new Date(data.birthDate),
          dataRilascioDoc: new Date(data.releaseDate),
          dataScadenzaDoc: new Date(data.expirationDate),
          email: data.email,
          firstPage: '', // TODO: RETRIEVE COOKIE
          firstReferral: '', // TODOD: RETRIEVE COOKIE
          lastReferral: '', // TODO: RETRIEVE COOKIE
          flagMarketing: data.marketingUsage ? 1 : 0,
          flagProfilazione: data.profilingUsage ? 1 : 0,
          idOtpEmail: '',
          idOtpTelefono: '',
          idSessione: crypto.randomUUID(),
          indirizzoDomicilio: data.address,
          indirizzoResidenza: data.address,
          limiteVersamento: 100 * Number(data.weeklyMaximal),
          luogoRilascioDoc: data.releasePlace,
          nome: data.name,
          numeroDoc: data.documentNumber,
          password: data.password,
          provenienza: '',
          provinciaDomicilio: data.province,
          provinciaNascita: data.provinceOfBirth,
          provinciaResidenza: data.province,
          rispostaDomandaSegreta: data.secretAnswer,
          sesso: Number(data.gender),
          spidAccess: data.isSpid,
          telefonoMobile: data.telephone,
          username: data.username,
          btag: '',
        };
        return {
          method: 'POST',
          url: `${UserEndPointPrefix}/profile/contract/activate`,
          body: JSON.stringify(body),
        };
      }, // TODO: ADD ENDPOINT
    }),
    getBonus: builder.query<MappedBonusType, { type: string; filterVertical?: string }>({
      query: ({ type, filterVertical }) => ({
        url: `${UserEndPointPrefix}/bonus/${type}?verticale=${filterVertical ?? ''}`,
        timeout: 10000,
      }),
      transformResponse: (response: BonusApiResponse): MappedBonusType => {
        let bonus: MappedBonusType = {
          listBonusGold: [],
          listBonusCash: [],
          listFreespin: [],
          listFunbonus: [],
          filtroVerticale: [],
        };
        Object.keys(response).forEach(
          (
            key:
              | 'listBonusGold'
              | 'listBonusCash'
              | 'listFreespinCapecod'
              | 'listFreespinWm'
              | 'listFunbonusCapecod'
              | 'listFunbonusWm'
          ) => {
            const app: BonusFreespinFunBonus[] = [];
            if (key === 'listFreespinCapecod' || key === 'listFreespinWm') {
              if (key === 'listFreespinWm') {
                response[key].forEach((freespin) => {
                  const el: BonusFreespinFunBonus = {};
                  el.title = freespin.Reference;
                  el.producer = 'World March';
                  el.amount = freespin.FreeRoundCount;
                  el.startDate = freespin.StartDate;
                  el.expirationDate = freespin.ExpirationDate;
                  el.unit_amount = freespin.Balance;
                  el.games = [];
                  if (el.amount && el.amount > 0) app.push(el);
                });
              } else if (key === 'listFreespinCapecod') {
                response[key].forEach((freespin) => {
                  const el: BonusFreespinFunBonus = {};
                  el.producer = 'Capecod';
                  el.title = freespin.bonus;
                  el.amount = freespin.balanceAmount;
                  el.unit_amount = freespin.assignAmount;
                  el.startDate = freespin.date;
                  el.games = freespin.associatedGames;
                  el.status = freespin.state;
                  el.convertitoInCash = freespin.wonAmountCents;
                  if (el.amount && el.amount > 0 && el.status !== 'Terminato') app.push(el);
                });
              }
              bonus['listFreespin'] = [...bonus['listFreespin']!, ...app];
            } else if (key === 'listFunbonusCapecod' || key === 'listFunbonusWm') {
              if (key === 'listFunbonusWm') {
                response[key].forEach((freespin) => {
                  const el: BonusFreespinFunBonus = {};
                  el.title = freespin.Reference;
                  el.producer = 'World March';
                  el.amount = freespin.FreeRoundCount;
                  el.startDate = freespin.StartDate;
                  el.expirationDate = freespin.ExpirationDate;
                  el.unit_amount = freespin.Balance;
                  el.games = [];
                  if (el.amount && el.amount > 0) app.push(el);
                });
              } else if (key === 'listFunbonusCapecod') {
                response[key].forEach((freespin) => {
                  const el: BonusFreespinFunBonus = {};
                  el.producer = 'Capecod';
                  el.title = freespin.bonus;
                  el.amount = freespin.balanceAmountCents;
                  el.maxAmount = freespin.assignAmountCents;
                  el.unit_amount = freespin.assignAmountCents;
                  el.startDate = freespin.date;
                  el.games = freespin.associatedGames;
                  el.status = freespin.state;
                  el.convertitoInCash = freespin.wonAmountCents;
                  if (el.amount && el.amount > 0) app.push(el);
                });
              }
              bonus['listFunbonus'] = [...bonus['listFunbonus']!, ...app];
            } else if (key === 'listBonusGold') {
              const data = (response[key] as BonusGold[]).filter((el) => el.impo_bonus_w && el.impo_bonus_w > 0);
              bonus[key] = data;
            } else if (key === 'listBonusCash') {
              const data = (response[key] as BonusCash[]).filter((el) => el.saldoResiduo && el.saldoResiduo > 0);
              bonus[key] = data;
            } else {
              bonus[key] = response[key];
            }
          }
        );
        return bonus;
      },
    }),
    getMovimenti: builder.query<
      MovimentiResponseApi,
      {
        pageNumber?: number;
        pageSize?: number;
        dataDa: string;
        dataA: string;
        stato: string;
        direzione: string;
        tipologia: string;
      }
    >({
      query: ({ pageNumber, pageSize, dataDa, dataA, stato, direzione, tipologia }) => ({
        url: `${MovementEndPointPrefix}${
          pageNumber && pageSize ? `?pageNumber=${pageNumber}&pageSize=${pageSize}` : ``
        }`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          dataDa,
          dataA,
          stato,
          direzione,
          tipologia,
        },
      }),
      providesTags: ['Movimenti'],
    }),
    getDetailedMovimenti: builder.query<
      | DetailedBetResponseApi
      | DetailedBetResponseApiHappybet
      | DetailMovimentiResponseApi
      | DetailMovimentiResponseApiHappybet,
      { idBiglietto: string; idBigl: string; codTipoGioco: string }
    >({
      query: ({ idBiglietto, idBigl, codTipoGioco }) => ({
        url: `${isSnaiSite ? MovementEndPointPrefix : MovementEndPointPrefixHappybet}/details`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          carta: '', // obbligatorio, ma lasciare vuoto
          id_biglietto: idBiglietto,
          id_bigl: '', //idBigl,
          cod_tipo_gioco: codTipoGioco,
        },
      }),
    }),
    getScommesse: builder.query<
      ScommesseResponseApi,
      {
        pageNumber: number;
        pageSize: number;
        dataDa: string;
        dataA: string;
        stato: string;
        // hasCashout: boolean;
        tipologia: string;
        hasNoLimit?: boolean;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        dataDa,
        dataA,
        stato,
        tipologia,
        hasNoLimit,
        // hasCashout,
      }) => ({
        url: `${MovementEndPointPrefix}/betting?PageNumber=${pageNumber}&PageSize=${pageSize}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          dataDa: dataDa,
          dataA: dataA,
          stato: stato,
          tipologia: tipologia,
          hasNoLimit: isTruthy(process.env.NEXT_PUBLIC_BETTING_NO_LIMIT)
            ? isTruthy(process.env.NEXT_PUBLIC_BETTING_NO_LIMIT)
            : hasNoLimit,
          // hasCashout: hasCashout,
        },
      }),
      providesTags: ['Scommesse'],
    }),
    getPlayTime: builder.query<UsersGetStatsResDto, { period: string }>({
      query: ({ period }) => `${UserEndPointPrefix}/statistics?period=${period || ''}`,
    }),
    getCashoutValue: builder.query<
      CashoutResponseApi,
      {
        idBiglietto: string;
      }
    >({
      query: ({ idBiglietto }) => `${MovementEndPointPrefix}/cashout?idBiglietto=${idBiglietto}`,
    }),
    postCashoutTotale: builder.mutation<CashoutTotaleResponseApi, { idBiglietto: string; importo: number }>({
      query: ({ idBiglietto, importo }) => ({
        url: `${MovementEndPointPrefix}/cashout?idBiglietto=${idBiglietto}&importo=${importo}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Wallet'],
    }),
    contactUsAuth: builder.mutation<
      void,
      {
        from: string;
        subject: string;
        text: string;
        username: string;
        sendCopy: boolean;
      }
    >({
      query: ({ from, subject, text, username, sendCopy }) => ({
        url: `${UserEndPointPrefix}/support/auth`,
        method: 'POST',
        body: {
          from,
          subject,
          text,
          username,
          sendCopy,
        },
      }),
    }),
    contactUsNoAuth: builder.mutation<
      void,
      {
        from: string;
        subject: string;
        text: string;
        name: string;
        surname: string;
      }
    >({
      query: ({ from, subject, text, name, surname }) => ({
        url: `${UserEndPointPrefix}/support/noauth`,
        method: 'POST',
        body: {
          from,
          subject,
          text,
          name,
          surname,
        },
      }),
    }),
    cancelWithdrawal: builder.mutation<
      void,
      {
        data: {
          idTicket?: string;
          codiceCsmf?: number;
          idRequest?: string;
          importo?: number;
          descrizioneCircuito?: string | null;
          idUserSessio?: string;
          idTransaction?: string | null;
        };
        type: string;
      }
    >({
      query: ({ data, type }) => ({
        url: `${UserEndPointPrefix}/wallet/withdrawal/${type}/abort`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: data,
      }),
      invalidatesTags: ['Movimenti'],
    }),
    postPrivacyFlags: builder.mutation<any, { flagMarketing?: string; flagProfilazione?: string }>({
      query: ({ flagMarketing, flagProfilazione }) => ({
        url: `${UserEndPointPrefix}/profile/privacy`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          flag_marketing: flagMarketing,
          flag_profilazione: flagProfilazione,
        },
      }),
      // invalidatesTags: ['ProfileContract'],
    }),
    realTimeCashout: builder.query<PollingCashoutResponseApi, string[]>({
      query: (idsBiglietti) => ({
        url: `${MovementEndPointPrefix}/cashout/realtime`, //'http://localhost:3000/api/realtime',
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: [...idsBiglietti],
      }),
    }),
    getAvatars: builder.query<AvatarsResponseApi, void>({
      query: () => `users/user/profile/avatars`,
    }),
    saveAvatar: builder.mutation<void, { avatar: Avatar; carta?: string }>({
      query: ({ avatar, carta }) => ({
        url: `users/user/favorites/FAVORITE_AVATARS/${carta}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: avatar,
      }),
    }),
    getMyAvatar: builder.query<MyAvatar[], void>({
      query: () => `users/user/favorites/FAVORITE_AVATARS`,
    }),
    signUpCheckFiscalCode: builder.query<UsersApiGadBaseEsitoResDto, string>({
      query: (fiscalCode) => `users/user/profile/fiscalcode/check?fiscalCode=${fiscalCode}`,
    }),
    getCachedProfile: builder.query<UsersGetProfileResponse, void>({
      query: () => `users/user/profile`,
    }),
    sendInfo: builder.mutation<
      void,
      {
        from?: string;
        to: string;
        subject?: string;
        text: string;
        type: TIPOLOGY_SEND_INFO;
      }
    >({
      query: ({ from, to, subject, text, type }) => ({
        url: `${UserEndPointPrefix}/contact`,
        method: 'POST',
        body: {
          from,
          to,
          subject,
          text,
          type,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCachedProfileQuery,
  useLazyGetCachedProfileQuery,
  usePostFeedUnregistrationMutation,
  useLazyCheckUsernameQuery,
  useLazyCalculateFiscalCodeQuery,
  useLazySendOTPQuery,
  useLazyVerifyOTPQuery,
  useLazySendOTPForEditQuery,
  useLazyVerifyOTPForEditQuery,
  useLazyPostFeedbackQuery,
  useLazySignUpQuery,
  useLazyVerifyUserQuery,
  useLazyCheckFiscalCodeQuery,
  useLazyResetPasswordQuery,
  useLazyActiveAccountQuery,
  usePostSignUpDataMutation,
  useGetBonusQuery,
  useLazyGetBonusQuery,
  useGetMovimentiQuery,
  useGetDetailedMovimentiQuery,
  useGetScommesseQuery,
  useLazyGetScommesseQuery,
  useGetPlayTimeQuery,
  useLazyGetPlayTimeQuery,
  useGetCashoutValueQuery,
  usePostCashoutTotaleMutation,
  useContactUsAuthMutation,
  useContactUsNoAuthMutation,
  useLazyGetSessionDataQuery,
  useGetSessionDataQuery,
  useCancelWithdrawalMutation,
  usePostPrivacyFlagsMutation,
  useRealTimeCashoutQuery,
  useLazyRealTimeCashoutQuery,
  useGetAvatarsQuery,
  useSaveAvatarMutation,
  useGetMyAvatarQuery,
  useLazySignUpCheckFiscalCodeQuery,
  useSendInfoMutation,
  useSignUpSpidMutation,
} = userApi;
